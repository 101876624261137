
<template>
  <van-nav-bar title="主题列表" />

  <van-card
    v-for="(item, index) in uplist.list"
    :key="index"
    :tag="item.hot"
    :title="item.title"
    :desc="item.content"
    :thumb="item.image"
    @click-thumb="showview(item.vimagelist)"
  >
    <template #tags>
      <van-tag plain type="success">{{ item.tag }}</van-tag>
      <!-- <van-tag plain type="danger">经典版</van-tag> -->
    </template>

    <template #bottom>
      <div class="van-card__price">{{ item.createtime }}</div>
      <div class="van-card__num">
        <van-button
          plain
          hairline
          type="primary"
          size="mini"
          @click="conclick(item.id, item.title)"
          >升级</van-button
        >
      </div>
    </template>
  </van-card>

  <van-dialog v-model:show="showt" title="标题" show-cancel-button>
    <img src="https://img.yzcdn.cn/vant/apple-3.jpg" />
  </van-dialog>

  <Docker :currentIndex="0" />
  <Toast v-if="show" :message="toastMessage" />
</template>


<script>
import { reactive } from "vue";
import { get, post } from "../../utils/request";
import Docker from "../../components/Docker";
import Toast, { useToastEffect } from "../../components/Toast";
import { Dialog, ImagePreview } from "vant";
import { ref } from "vue";
const useUserEffect = () => {
  const uplist = reactive({ list: "" });

  const getUserInfo = async () => {
    const result = await get("getlist");
    console.log(result);
    if (result?.data.errno === 0 && result?.data) {
      const { list } = result.data;
      uplist.list = list || "";
      console.log(uplist);
    }
  };
  getUserInfo();
  return uplist;
};

const uploadEffect = (showToast) => {
  const conclick = (id, title) => {
    Dialog.confirm({
      title: "请确认是否升级主题:",
      message: title,
    })
      .then(() => {
        upClick(id);
      })
      .catch(() => {
        console.log("***");
      });
  };

  const showview = (imagelist) => {
    // console.log(imagelist.split(','))
    let imagerow = imagelist.split(',')
    ImagePreview({
      images: [
        imagerow[0],
        imagerow[1],
        imagerow[2],
      ],
      closeable: true,
    });
  };

  const upClick = async (id) => {
    try {
      const result = await post("utheme", {
        id: id,
        token: sessionStorage.token,
      });

      if (result?.errno === 0) {
        console.log(result);
      } else {
        console.log(result);
        showToast("登陆失败");
      }
    } catch (e) {
      console.log(e);
      showToast("请求失败");
    }
  };
  return { upClick, conclick,showview };
};

export default {
  components: { Docker, Toast, [Dialog.Component.name]: Dialog.Component },
  setup() {
    const showt = ref(false);
    const { show, toastMessage, showToast } = useToastEffect();

    const uplist = useUserEffect();
    const { upClick, conclick,showview } = uploadEffect(showToast);
    return { showt, show, showToast, toastMessage, showview,conclick, uplist, upClick };
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/viriables.scss";
@import "../../style/mixins.scss";
.position {
  position: relative;
  padding: 0.16rem 0.24rem 0.16rem 0;
  line-height: 0.22rem;
  font-size: 0.16rem;
  @include ellipsis;
  .position__icon {
    position: relative;
    top: 0.01rem;
    font-size: 0.2rem;
  }
  .position_notice {
    position: absolute;
    right: 0;
    top: 0.17rem;
    font-size: 0.2rem;
  }
  color: $content-fontcolor;
}
.search {
  margin-bottom: 0.12rem;
  line-height: 0.32rem;
  background: $search-bgColor;
  color: $search-fontColor;
  border-radius: 0.16rem;
  .iconfont {
    display: inline-block;
    padding: 0 0.08rem 0 0.16rem;
    font-size: 0.16rem;
  }
  &__text {
    display: inline-block;
    font-size: 0.14rem;
  }
}
.banner {
  height: 0;
  overflow: hidden;
  padding-bottom: 25.4%;
  &__img {
    width: 100%;
  }
}
.icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.16rem;
  &__item {
    width: 25%;
    &__img {
      display: block;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0 auto;
    }
    &__desc {
      margin: 0.06rem 0 0.16rem 0;
      text-align: center;
      color: $content-fontcolor;
    }
  }
  a {
    text-decoration: none;
  }
}
.gap {
  margin: 0 -0.18rem;
  height: 0.1rem;
  background: $content-bgColor;
}
</style>